import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';

import { sidebarNavigation, SidebarNavigationItem } from '../layouts/main/sider/sidebarNavigation';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import * as url from 'url';

export function Appcrumb({ children }: { children?: ReactNode }) {
  const [currentRoute, setCurrentRoute] = useState<SidebarNavigationItem | null>(null);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    const parent = sidebarNavigation.find((nav) => nav.children?.find((x) => x.url && pathname.includes(x.url)));
    if (parent) {
      setCurrentRoute(parent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentRoute ? (
    <Col sm={15} md={21} lg={22}>
      <Breadcrumb>
        <>
          <BreadcrumbItem>{t(currentRoute.title)}</BreadcrumbItem>
          <BreadcrumbItem>
            {t(currentRoute.children?.find((child) => child.url === pathname)?.title || '')}
          </BreadcrumbItem>
          {children}
        </>
      </Breadcrumb>
    </Col>
  ) : null;
}
