import { ParameterModel } from '@app/domain/ParameterModel';
import { useState, useEffect, useMemo } from 'react';

export default function useParameters(groupName?: string) {
  const parameters = localStorage.getItem('parameters');

  const [parameterGroup, setParameterGroup] = useState<ParameterModel[]>([]);

  useEffect(() => {
    if (parameters) {
      const parsedParameter = JSON.parse(parameters);
      setParameterGroup(parsedParameter.filter((p: ParameterModel) => (groupName ? p.group === groupName : p)));
    }
  }, [groupName, parameters]);

  return useMemo(() => ({ parameterGroup }), [parameterGroup]);
}
