import { httpApi } from '@app/api/http.api';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  emailIdentityPhone: string;
}

export interface SecurityCodePayload {
  verificationCode: number;
  emailIdentityPhone: string;
}

export interface NewPasswordData {
  emailIdentityPhone: string;
  password: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  accessToken: string;
  user: UserModel;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('api/v1/auth/login', { ...loginPayload }).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('api/v1/auth/signUp', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('api/v1/auth/reset-password', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('api/v1/auth/check-verification-code', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('api/v1/auth/set-new-password', { ...newPasswordData }).then(({ data }) => data);

export const checkUserExistence = (identityNumber: string): Promise<boolean> =>
  httpApi.get<boolean>(`api/v1/user/exist?identityNumber=${identityNumber}`).then(({ data }) => data);

export const checkCitizenExistence = (identityNumber: string): Promise<boolean> =>
  httpApi.get<boolean>(`api/v1/citizens/${identityNumber}`).then(({ data }) => data);
