import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUser from '@app/hooks/useUser';
import { ActiveTournamentWithRefereeAssignmentInfoModel } from '@app/domain/TournamentModel';
import { notificationController } from '@app/controllers/notificationController';
import { Text as H4 } from '@app/components/common/typography/H4/H4.styles';
import TournamentWithAssignmentInfoCard from '@app/components/tournament/TournamentWithAssignmentInfoCard';
import {
  acceptRefereeAssignmentToTournament,
  declineRefereeAssignmentToTournament,
  getRefereeActiveTournamentsWithAssignmentsInfo,
} from '@app/api/tournament.api';

const RefereeDashboard = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [refreshDataCount, setRefreshDataCount] = useState(0);

  const [tournaments, setTournaments] = useState<ActiveTournamentWithRefereeAssignmentInfoModel[]>([]);

  useEffect(() => {
    if (user?.identityNumber) {
      getRefereeActiveTournamentsWithAssignmentsInfo(user.identityNumber).then(setTournaments);
    }
  }, [user?.identityNumber, refreshDataCount]);

  const onAcceptAssignment = (id: number) => {
    acceptRefereeAssignmentToTournament(id).then(() => {
      setRefreshDataCount((refData) => refData + 1);
      notificationController.success({ message: t('translation:accepted') });
    });
  };

  const onRejectAssignment = (id: number) => {
    if (user?.identityNumber) {
      declineRefereeAssignmentToTournament(id).then(() => {
        setRefreshDataCount((refData) => refData + 1);
        notificationController.success({ message: t('translation:declined') });
      });
    }
  };

  return (
    <div>
      <H4 style={{ marginBottom: '2rem' }}>{t('tournament.tournamentsIHaveBeenAssigned')}</H4>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {tournaments.map((item, index) => (
          <TournamentWithAssignmentInfoCard
            key={index}
            tournament={item}
            onAcceptAssignment={onAcceptAssignment}
            onRejectAssignment={onRejectAssignment}
          />
        ))}
      </div>
    </div>
  );
};

export default RefereeDashboard;
