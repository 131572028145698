import axios from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';
import { notificationController } from '@app/controllers/notificationController';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

  return config;
});

httpApi.interceptors.response.use(undefined, (error: any) => {
  if (error.toJSON().status === 401) {
    localStorage.clear();
    window.location.href = '/auth/login';
  }
  console.log(error.toJSON());
  notificationController.error({ message: error.response?.data.message || error.message });
  return { error };
});

export interface ApiErrorData {
  message: string;
}
