import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';
import { Menu } from 'antd';
import useUser from '@app/hooks/useUser';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const location = useLocation();

  const authSideBar: SidebarNavigationItem[] = [];
  sidebarNavigation.forEach((nav) => {
    const rolePermissions = user?.role?.map((r) => r.permissions)?.flat() || [];
    const permission = rolePermissions.find((p) => p.resourceKey === nav.resourceKey);
    const children = nav.children;
    const newChildren: any = [];
    children?.forEach((c) => {
      const cPermission = rolePermissions.find((p) => p.resourceKey === c.resourceKey);
      if (cPermission && cPermission?.allowedActions.includes(c.actionType || 'Etkisiz')) {
        newChildren.push(c);
      }
    });
    if (
      (permission?.allowedActions.includes(nav.actionType || 'Etkisiz') && newChildren && newChildren.length > 0) ||
      permission?.allowedActions.includes(nav.actionType || 'Etkisiz')
    ) {
      nav.children = newChildren;
      authSideBar.push(nav);
    }
  });

  const sidebarNavFlat = authSideBar.reduce(
    (result: SidebarNavigationItem[], current) =>
      result.concat(current.children && current.children.length > 0 ? current.children : current),
    [],
  );
  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = authSideBar.find(({ children }) => children?.some(({ url }) => url === location.pathname));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
    >
      {authSideBar.map((nav) =>
        nav.children && nav.children.length > 0 ? (
          <Menu.SubMenu
            key={nav.key}
            title={t(nav.title)}
            icon={nav.icon}
            onTitleClick={() => setCollapsed(false)}
            popupClassName="d-none"
          >
            {nav.children.map((childNav) => (
              <Menu.Item key={childNav.key} title="">
                <Link to={childNav.url || ''}>{t(childNav.title)}</Link>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        ) : (
          <Menu.Item key={nav.key} title="" icon={nav.icon}>
            <Link to={nav.url || ''}>{t(nav.title)}</Link>
          </Menu.Item>
        ),
      )}
    </S.Menu>
  );
};

export default SiderMenu;
