import React from 'react';
import {
  BookOutlined,
  BankOutlined,
  HomeOutlined,
  PlusCircleOutlined,
  FileOutlined,
  CrownOutlined,
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  resourceKey?: string;
  actionType?: string;
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'sideBar.home',
    resourceKey: 'MENU_DASHBOARD',
    actionType: 'Get',
    key: 'dashboard',
    url: '/',
    icon: <HomeOutlined />,
  },
  {
    title: 'sideBar.licence',
    resourceKey: 'MENU_LISANS',
    actionType: 'Get',
    key: 'license',
    url: '/license',
    icon: <BankOutlined />,
    children: [
      {
        title: 'sideBar.licence',
        resourceKey: 'MENU_LISANS',
        actionType: 'Get',
        key: 'licence',
        url: '/licence',
      },
      {
        title: 'sideBar.licenceUpdate',
        resourceKey: 'MENU_LISANS_GUNCELLEME',
        actionType: 'Get',
        key: 'license-update',
        url: '/license-update',
      },
      {
        title: 'sideBar.citizenTransactions',
        resourceKey: 'MENU_LISANS_VATANDAS_ISLEMLERI',
        actionType: 'Get',
        key: 'license-citizens',
        url: '/citizens',
      },
    ],
  },
  {
    title: 'sideBar.tournament',
    resourceKey: 'MENU_TURNUVA',
    actionType: 'Get',
    key: 'tournament',
    url: '/tournament',
    icon: <CrownOutlined />,
    children: [
      {
        key: 'sideBar.tournament',
        resourceKey: 'MENU_TURNUVA_LISTELE',
        actionType: 'Get',
        title: 'sideBar.tournaments',
        url: '/tournament',
      },
      {
        key: 'sideBar.tournament-operations',
        resourceKey: 'MENU_TURNUVA_LISTELE',
        actionType: 'Get',
        title: 'sideBar.tournament-operations',
        url: '/tournament/operations',
      },
      // {
      //   key: 'sideBar.',
      //   resourceKey: 'MENU_TURNUVA_LISTELE',
      //   actionType: 'Get',
      //   title: 'Listele',
      //   url: '/tournament-referee-list',
      // },
    ],
  },
  {
    title: 'translation:league',
    resourceKey: 'MENU_DASHBOARD',
    actionType: 'Get',
    key: 'league',
    url: '/league/league-tournaments',
    icon: <CrownOutlined />,
    children: [
      {
        key: 'club-players',
        resourceKey: 'MENU_DASHBOARD',
        actionType: 'Get',
        title: 'translation:league-tournaments',
        url: '/league/league-tournaments',
      },
      {
        key: 'matching',
        resourceKey: 'MENU_DASHBOARD',
        actionType: 'Get',
        title: 'translation:league-referee-operations',
        url: '/league/referee-operations',
      },
    ],
  },
  {
    title: 'UKD',
    resourceKey: 'MENU_TURNUVA',
    actionType: 'Get',
    key: 'ukd',
    url: '/ukd',
    icon: <CrownOutlined />,
    children: [
      {
        key: 'ukd-operations',
        resourceKey: 'MENU_TURNUVA_LISTELE',
        actionType: 'Get',
        title: 'ukd-operations',
        url: '/ukd/operations',
      },
    ],
  },
  {
    title: 'İçerik Yönetimi',
    resourceKey: 'MENU_ICERİK_YONETIMI',
    actionType: 'Get',
    key: 'content-manager',
    url: '/content',
    icon: <FileOutlined />,
    children: [
      {
        key: 'content-news',
        resourceKey: 'MENU_HABERLER_DUYURULAR',
        actionType: 'Get',
        title: 'Makaleler',
        url: '/news',
      },
      {
        key: 'content-information',
        resourceKey: 'MENU_INFORMATION',
        actionType: 'Get',
        title: 'Bilgi Sayfaları',
        url: '/information',
      },
      {
        key: 'content-menu',
        resourceKey: 'MENU_MENUS',
        actionType: 'Get',
        title: 'Menüler',
        url: '/menu',
      },
      {
        key: 'content-settings',
        resourceKey: 'MENU_SETTINGS',
        actionType: 'Get',
        title: 'Ayarlar',
        url: '/settings',
      },
    ],
  },
  {
    title: 'Temsilcilik Birimleri',
    resourceKey: 'MENU_KURULLAR',
    actionType: 'Get',
    key: 'boards',
    icon: <FileOutlined />,
    children: [
      {
        title: 'Birim İşlemleri',
        resourceKey: 'MENU_KURULLAR_MHK_ISLEMLER',
        actionType: 'Get',
        key: 'board-operations',
        url: '/boards',
        icon: <FileOutlined />,
      },
      {
        title: 'Birim Atama İşlemleri',
        resourceKey: 'MENU_KURULLAR_MHK_ISLEMLER',
        actionType: 'Get',
        key: 'board-member-operations',
        url: '/boards-member',
        icon: <FileOutlined />,
      },
      // {
      //   title: 'sideBar.crfOperations',
      //   resourceKey: 'MENU_KURULLAR_MHK_ISLEMLER',
      //   actionType: 'Get',
      //   key: 'mhk-operations',
      //   url: '/mhk-operations',
      //   icon: <FileOutlined />,
      // },
    ],
  },

  {
    title: 'sideBar.provincialOffices',
    resourceKey: 'MENU_IL_TEMSILCILIKLERI',
    actionType: 'Get',
    key: 'city-ambassador',
    url: '/city-ambassador',
    icon: <FileOutlined />,
    children: [
      {
        title: 'sideBar.provinces',
        resourceKey: 'MENU_IL_TEMSILCILIKLERI_ILLER',
        actionType: 'Get',
        key: 'city-representatives',
        url: '/city-representatives',
      },
      {
        title: 'sideBar.instruction',
        resourceKey: 'MENU_IL_TEMSILCILIKLERI_YONERGE',
        actionType: 'Get',
        key: 'instruction',
        url: '/instruction',
      },
      {
        title: 'sideBar.club',
        resourceKey: 'MENU_IL_TEMSILCILIKLERI_KULUP',
        actionType: 'Get',
        key: 'club',
        url: '/club',
        icon: <FileOutlined />,
      },
      {
        title: 'İlçe Temsilcilikleri',
        // TODO RESOURCE WILL BE CHANGED
        resourceKey: 'MENU_IL_TEMSILCILIKLERI_ILLER',
        actionType: 'Get',
        key: 'county-representatives',
        url: '/club',
        icon: <FileOutlined />,
      },
    ],
  },

  {
    title: 'sideBar.administrativeAffairs',
    resourceKey: 'MENU_IDARI_ISLER',
    actionType: 'Get',
    key: 'administrative-affairs',
    url: '/administrative-affairs',
    icon: <FileOutlined />,
    children: [
      {
        key: 'inventory-management',
        resourceKey: 'MENU_IDARI_ISLER_ENVANTER',
        actionType: 'Get',
        title: 'sideBar.inventoryManagement',
        url: '/product',
      },
      {
        key: 'inventory-stock',
        resourceKey: 'MENU_IDARI_ISLER_URUN_STOK',
        actionType: 'Get',
        title: 'sideBar.productStocks',
        url: '/product-stock',
      },
      {
        key: 'product-debit',
        resourceKey: 'MENU_IDARI_URUN_ZIMMET',
        actionType: 'Get',
        title: 'sideBar.productDebit',
        url: '/product-debit',
      },
      {
        key: 'product-debit-unmovable',
        resourceKey: 'MENU_IDARI_URUN_ZIMMET_TASINMAZ',
        actionType: 'Get',
        title: 'Taşınmazlar Talepleri',
        url: '/product-debit-unmovable',
      },
      {
        key: 'product-offer',
        resourceKey: 'MENU_IDARI_ISLER_TEKLIF',
        actionType: 'Get',
        title: 'Satınalma Talepleri',
        url: '/offer',
      },
    ],
  },
  {
    title: 'sideBar.integration',
    resourceKey: 'MENU_ENTEGRASYON',
    actionType: 'Get',
    key: 'integration',
    url: '/integration',
    icon: <FileOutlined />,
    children: [
      {
        title: 'sideBar.ministryOfSport',
        resourceKey: 'MENU_ENTEGRASYON_GSB',
        actionType: 'Get',
        key: 'sport-administrive',
        url: '/sport-administrive',
      },
      {
        title: 'sideBar.bank',
        resourceKey: 'MENU_ENTEGRASYON_BANKA',
        actionType: 'Get',
        key: 'bank',
        url: '/bank',
      },
      {
        title: 'sideBar.gsm',
        resourceKey: 'MENU_ENTEGRASYON_GSM',
        actionType: 'Get',
        key: 'gsm',
        url: '/gsm',
      },
    ],
  },
  {
    title: 'sideBar.contact',
    resourceKey: 'MENU_ILETISIM',
    actionType: 'Get',
    key: 'communication',
    url: '/communication',
    icon: <FileOutlined />,
    children: [
      {
        key: 'sendNotification',
        resourceKey: 'MENU_ILETISIM_BILDIRIM_GONDER',
        actionType: 'Get',
        title: 'sideBar.sendNotification',
        url: '/send-notification',
      },
    ],
  },
  {
    title: 'sideBar.management',
    resourceKey: 'MENU_YONETIM',
    actionType: 'Get',
    key: 'yönetim',
    icon: <BankOutlined />,
    children: [
      {
        title: 'sideBar.users',
        key: 'user',
        resourceKey: 'MENU_YONETIM_KULLANICILAR',
        actionType: 'Get',
        url: '/user',
        icon: <BankOutlined />,
      },
      {
        title: 'sideBar.roleDefinition',
        resourceKey: 'MENU_YONETIM_ROL_TANIMLAMA',
        actionType: 'Get',
        key: 'role-management',
        url: '/role',
        icon: <BankOutlined />,
      },
      {
        title: 'sideBar.resource',
        resourceKey: 'MENU_YONETIM_RESOURCE',
        actionType: 'Get',
        key: 'resource-management',
        url: '/resource',
      },
      {
        title: 'sideBar.parameter',
        resourceKey: 'MENU_YONETIM_PARAMETER',
        actionType: 'Get',
        key: 'parameter',
        url: '/parameter',
      },
    ],
  },

  // {
  //   title: 'common.apps',
  //   key: 'apps',
  //   icon: <HomeOutlined />,
  //   children: [
  //     {
  //       title: 'common.feed',
  //       key: 'feed',
  //       url: '/apps/feed',
  //     },
  //     {
  //       title: 'common.kanban',
  //       key: 'kanban',
  //       url: '/apps/kanban',
  //     },
  //   ],
  // },
];
