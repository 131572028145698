import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';
import NewUserRegisterPage from '@app/pages/NewUserRegister';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import { Appcrumb } from '../appcrumb/Appcrumb';
import InstructionFile from '@app/components/instructionForm/form1';
import HomePage from '@app/pages/home/HomePage';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const Logout = React.lazy(() => import('./Logout'));

const ResourcePage = React.lazy(() => import('@app/pages/resource/ResourcePage'));
const UserPage = React.lazy(() => import('@app/pages/user/UserPage'));
const RolePage = React.lazy(() => import('@app/pages/role/RolePage'));
const BoardPage = React.lazy(() => import('@app/pages/board/BoardPage'));
const IntegrationPage = React.lazy(() => import('@app/pages/integration/IntegrationPage'));
const CitizensPage = React.lazy(() => import('@app/pages/citizens/CitizensPage'));
const ParameterPage = React.lazy(() => import('@app/pages/parameter/ParameterPage'));
const InstructionPage = React.lazy(() => import('@app/pages/instruction/InstructionPage'));
const ProductPage = React.lazy(() => import('@app/pages/product/ProductPage'));
const ProductStockPage = React.lazy(() => import('@app/pages/productStock/ProductStockPage'));
const CityRepresentativesPage = React.lazy(() => import('@app/pages/city-representatives/CityRepresentatives'));
const ProductDebitPage = React.lazy(() => import('@app/pages/product-debit/ProductDebitPage'));
const ProductDebitUnmovablePage = React.lazy(
  () => import('@app/pages/product-debit-unmovable/ProductDebitUnmovablePage'),
);
const BoardMemberPage = React.lazy(() => import('@app/pages/board-member/BoardMemberPage'));
const OfferPage = React.lazy(() => import('@app/pages/offer/OfferPage'));
const LicencePage = React.lazy(() => import('@app/pages/licence/LicencePage'));
const TournamentPage = React.lazy(() => import('@app/pages/tournament/TournamentPage'));
const TournamentRefereesPage = React.lazy(() => import('@app/pages/tournament/TournamentRefereesPage'));
const TournamentOperationsHomePage = React.lazy(() => import('@app/pages/tournament/TournamentOperationsHomePage'));
const LeagueTournamentsPage = React.lazy(() => import('@app/pages/league/LeagueTournamentsPage'));
const LeagueRefereeOperationsPage = React.lazy(() => import('@app/pages/league/LeagueRefereeOperationsPage'));
const UkdOperationsHomePage = React.lazy(() => import('@app/pages/ukd/UkdOperationsHomePage'));
const ContentPage = React.lazy(() => import('@app/pages/content/ContentPage'));
const InformationPage = React.lazy(() => import('@app/pages/information/InformationPage'));
const MenuPage = React.lazy(() => import('@app/pages/menu/MenuPage'));
const SettingsPage = React.lazy(() => import('@app/pages/settings/SettingsPage'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

const Resources = withLoading(ResourcePage);
const Users = withLoading(UserPage);
const Roles = withLoading(RolePage);
const Boards = withLoading(BoardPage);
const Citizens = withLoading(CitizensPage);
const Integrations = withLoading(IntegrationPage);
const Parameters = withLoading(ParameterPage);
const Instructions = withLoading(InstructionPage);
const Products = withLoading(ProductPage);
const ProductStocks = withLoading(ProductStockPage);
const CityRepresentatives = withLoading(CityRepresentativesPage);
const ProductDebit = withLoading(ProductDebitPage);
const ProductDebitUnmovable = withLoading(ProductDebitUnmovablePage);
const BoardMembers = withLoading(BoardMemberPage);
const Offer = withLoading(OfferPage);
const Licence = withLoading(LicencePage);
const Tournament = withLoading(TournamentPage);
const TournamentReferees = withLoading(TournamentRefereesPage);
const TournamentOperationsHome = withLoading(TournamentOperationsHomePage);
const LeagueTournaments = withLoading(LeagueTournamentsPage);
const LeagueRefereeOperations = withLoading(LeagueRefereeOperationsPage);
const UkdOperationsHome = withLoading(UkdOperationsHomePage);
const Content = withLoading(ContentPage);
const Information = withLoading(InformationPage);
const Menu = withLoading(MenuPage);
const Settings = withLoading(SettingsPage);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route path="" element={<HomePage />} /> {/* Intentionally no lazy loading for home */}
          <Route path="resource" element={<Resources />} />
          <Route path="user" element={<Users />} />
          <Route path="role" element={<Roles />} />
          <Route path="integration" element={<Integrations />} />
          <Route path="boards" element={<Boards />} />
          <Route path="boards-member" element={<BoardMembers />} />
          <Route path="citizens" element={<Citizens />} />
          <Route path="parameter" element={<Parameters />} />
          <Route path="instruction" element={<Instructions />} />
          <Route path="product" element={<Products />} />
          <Route path="product-stock" element={<ProductStocks />} />
          <Route path="city-representatives" element={<CityRepresentatives />} />
          <Route path="product-debit" element={<ProductDebit />} />
          <Route path="product-debit-unmovable" element={<ProductDebitUnmovable />} />
          <Route path="offer" element={<Offer />} />
          <Route path="licence" element={<Licence />} />
          <Route path="tournament" element={<Tournament />} />
          <Route path="tournament/:id" element={<TournamentReferees />} />
          <Route path="tournament/operations" element={<TournamentOperationsHome />} />
          <Route path="league/league-tournaments" element={<LeagueTournaments />} />
          <Route path="league/referee-operations" element={<LeagueRefereeOperations />} />
          <Route path="ukd/operations" element={<UkdOperationsHome />} />
          {/*<Route path="tournament/operations/:id" element={<TournamentOperations />} />*/}
          <Route path="news" element={<Content />} />
          <Route path="information" element={<Information />} />
          <Route path="menu" element={<Menu />} />
          <Route path="settings" element={<Settings />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
        </Route>
        <Route path="instruction-file/:id" element={<InstructionFile />}></Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route path="new-user" element={<NewUserRegisterPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
