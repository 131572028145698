import { PaginatedResponse } from '@app/domain/PaginatedResponse';
import { PaginationModel } from '@app/domain/PaginationModel';
import { UserModel } from '@app/domain/UserModel';
import { AxiosResponse } from 'axios';
import { httpApi } from './http.api';

const URI = 'api/v1/user';

export const getUsers = (pagination: PaginationModel): Promise<AxiosResponse<PaginatedResponse<UserModel>>> => {
  return httpApi.get(URI, {
    params: { ...pagination },
  });
};

export const createPlayer = (entity: any) => httpApi.post('api/v1/user/player', entity);

export const searchUsers = (
  identityNumber: string,
  pagination: PaginationModel,
): Promise<AxiosResponse<PaginatedResponse<UserModel>>> =>
  httpApi.get(`${URI}/search`, {
    params: { identityNumber, ...pagination },
  });

export const getByUserId = (id: number) => httpApi.get(`${URI}/${id}`);

export const insertUser = (entity: UserModel) => httpApi.post(URI, entity);

export const updateUser = (entity: UserModel) => httpApi.put(URI, entity);

export const deleteUser = (id: number) => httpApi.delete(`${URI}/${id}`);
