import React, { useEffect, useMemo } from 'react';
import { ActiveTournamentModel, UnregisterPlayerForTournamentDTO } from '@app/domain/TournamentModel';
import { Modal } from '@app/components/common/Modal/Modal';
import useUser from '@app/hooks/useUser';
import { useTranslation } from 'react-i18next';
import { Descriptions, Form } from 'antd';
import useParameters from '@app/hooks/useParameters';
import { TextArea } from '@app/components/common/inputs/Input/Input';

const TournamentRegistrationCancelModal = ({
  tournament,
  onComplete,
  onCloseRequest,
}: {
  tournament?: ActiveTournamentModel;
  onComplete?: (data: UnregisterPlayerForTournamentDTO) => void;
  onCloseRequest?: () => void;
}) => {
  const { t } = useTranslation();

  const { user } = useUser();

  const [form] = Form.useForm<{ reasonForCancellation: string }>();

  const parameterCities = useParameters('ILLER');

  const userCity = useMemo(() => {
    return parameterCities.parameterGroup.find((city) => city.id == user?.cityPId)?.name;
  }, [parameterCities, user?.cityPId]);

  useEffect(() => {
    form.resetFields();
  }, [tournament]);

  return (
    <Modal
      title={t('translation:tournament.registration-cancellation')}
      visible={!!tournament}
      closable={true}
      onCancel={onCloseRequest}
      onOk={form.submit}
      okText={t('translation:cancel-registration')}
      width={500}
      centered={true}
    >
      {tournament && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Descriptions layout="horizontal" size="small" column={1}>
            <Descriptions.Item label={t('translation:tournament.tournamentName')}>{tournament.name}</Descriptions.Item>
            <Descriptions.Item label={t('translation:fullName')}>
              {user?.firstName} {user?.lastName}
            </Descriptions.Item>
            <Descriptions.Item label={t('translation:citizensTable.province')}>{userCity}</Descriptions.Item>
            <Descriptions.Item label={t('translation:category')}>{/*TODO API*/}</Descriptions.Item>
          </Descriptions>
          <Form
            form={form}
            layout="vertical"
            size="small"
            onFinish={(values) => {
              onComplete?.({
                tournamentId: tournament!.id,
                identityNumber: user!.identityNumber,
                reasonForCancellation: values.reasonForCancellation,
              });
            }}
          >
            <Form.Item
              name="reasonForCancellation"
              label={t('translation:why-cancel-registration')}
              rules={[{ required: true, message: t('translation:required') }]}
            >
              <TextArea size="small" />
            </Form.Item>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default TournamentRegistrationCancelModal;
