import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Space } from 'antd';
import ReactCountryFlag from 'react-country-flag';
import { RadioBtn } from '../SettingsOverlay/SettingsOverlay.styles';
import { useLanguage } from '@app/hooks/useLanguage';

export const LanguagePicker: React.FC = () => {
  const { language, setLanguage } = useLanguage();

  const { t } = useTranslation();

  return (
    <Radio.Group defaultValue={language} onChange={(e) => setLanguage(e.target.value)}>
      <Space direction="vertical">
        <RadioBtn value="en">
          <Space align="center">
            <Space>{t('common.languageEnglish')}</Space>
            <ReactCountryFlag svg countryCode="GB" />
          </Space>
        </RadioBtn>
        {/* <RadioBtn value="de">
          <Space align="center">
            <Space>{t('common.languageDeutsch')}</Space>
            <ReactCountryFlag svg countryCode="DE" />
          </Space>
        </RadioBtn> */}
        <RadioBtn value="tr">
          <Space align="center">
            <Space>{t('common.languageTurkish')}</Space>
            <ReactCountryFlag svg countryCode="TR" />
          </Space>
        </RadioBtn>
      </Space>
    </Radio.Group>
  );
};
