import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { DropdownMenu } from '@app/components/header/Header.styles';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <DropdownMenu selectable={false} {...props}>
      <S.MenuItem key={0} onClick={() => navigate('profile')}>
        <S.Text>
          <Link to="#">{t('profile.title')}</Link>
        </S.Text>
      </S.MenuItem>
      <S.ItemsDivider />
      <S.MenuItem key={1} onClick={() => navigate('logout')}>
        <S.Text>
          <Link to="#">{t('header.logout')}</Link>
        </S.Text>
      </S.MenuItem>
    </DropdownMenu>
  );
};
