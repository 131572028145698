import { ParameterModel } from '@app/domain/ParameterModel';
import { PaginatedResponse } from '@app/domain/PaginatedResponse';
import { PaginationModel } from '@app/domain/PaginationModel';
import { AxiosResponse } from 'axios';
import { httpApi } from './http.api';

const URI = 'api/v1/parameter';

export const getParameters = (
  pagination: PaginationModel,
): Promise<AxiosResponse<PaginatedResponse<ParameterModel>>> => {
  return httpApi.get(URI, {
    params: { ...pagination },
  });
};

export const searchParameters = (
  parameterName: string,
  pagination: PaginationModel,
): Promise<AxiosResponse<PaginatedResponse<ParameterModel>>> =>
  httpApi.get(`${URI}/search`, {
    params: { parameterName, ...pagination },
  });

export const insertParameters = (entity: ParameterModel) => httpApi.post(URI, entity);

export const updateParameters = (entity: ParameterModel) => httpApi.put(URI, entity);

export const deleteParameters = (id: number) => httpApi.delete(`${URI}/${id}`);

export const getAllParameters = () => httpApi.get(`${URI}/getallparameters`);
