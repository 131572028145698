import axios from 'axios';
import { readToken } from '@app/services/localStorage.service';
import { notificationController } from '@app/controllers/notificationController';

export const httpApi2 = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpApi2.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

  return config;
});

httpApi2.interceptors.response.use(undefined, (error) => {
  console.log(error.toJSON());
  notificationController.error({ message: error.response?.data.message || error.message });
  if (error.toJSON().status === 401) {
    localStorage.clear();
    window.location.href = '/auth/login';
  }
  // Reject error to be caught instead of resending it as a normal error
  return Promise.reject(error);
});
