import React, { useEffect, useMemo, useState } from 'react';
import { ActiveTournamentModel, RegisterPlayerForTournamentDTO, TournamentCategory } from '@app/domain/TournamentModel';
import { Select } from '@app/components/common/selects/Select/Select';
import { Modal } from '@app/components/common/Modal/Modal';
import useUser from '@app/hooks/useUser';
import { Trans, useTranslation } from 'react-i18next';
import { Descriptions, Form } from 'antd';
import { getPlayerAvailableCategoriesForTournament } from '@app/api/tournament.api';
import { H4 } from '@app/components/common/typography/H4/H4';
import useParameters from '@app/hooks/useParameters';
import { Checkbox } from '@app/components/common/Checkbox/Checkbox';
import { Link } from 'react-router-dom';
import { Button } from '@app/components/common/buttons/Button/Button';

const TournamentRegistrationModal = ({
  tournament,
  onComplete,
  onCloseRequest,
}: {
  tournament?: ActiveTournamentModel;
  onComplete?: (data: RegisterPlayerForTournamentDTO) => void;
  onCloseRequest?: () => void;
}) => {
  const { t } = useTranslation();

  const { user } = useUser();

  const [form] = Form.useForm<{ categoryKey: number; acceptedKvkk: boolean }>();

  const [readKvkk, setReadKvkk] = useState(false);

  const parameterCities = useParameters('ILLER');

  const userCity = useMemo(() => {
    return parameterCities.parameterGroup.find((city) => city.id == user?.cityPId)?.name;
  }, [parameterCities, user?.cityPId]);

  const [playerAvailableCategoriesForTournament, setPlayerAvailableCategoriesForTournament] = useState<
    TournamentCategory[]
  >([]);

  useEffect(() => {
    if (tournament?.id && user?.identityNumber) {
      getPlayerAvailableCategoriesForTournament(user.identityNumber, tournament.id).then(
        setPlayerAvailableCategoriesForTournament,
      );
    } else {
      setPlayerAvailableCategoriesForTournament([]);
    }
  }, [tournament?.id, user?.identityNumber]);

  const categories = useMemo(() => {
    return playerAvailableCategoriesForTournament.map((category) => {
      return { label: category.name, value: category.key };
    });
  }, [playerAvailableCategoriesForTournament]);

  useEffect(() => {
    form.resetFields();
  }, [tournament]);

  return (
    <Modal
      title={t('translation:registerForTournament')}
      visible={!!tournament}
      closable={true}
      onCancel={onCloseRequest}
      onOk={() => {
        !readKvkk ? setReadKvkk(true) : form.submit();
      }}
      okText={!readKvkk ? t('translation:common.continue') : t('translation:join')}
      width={500}
      centered={true}
    >
      {tournament &&
        (readKvkk ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Descriptions layout="horizontal" size="small" column={1}>
              <Descriptions.Item label={t('translation:tournament.tournamentName')}>
                {tournament.name}
              </Descriptions.Item>
              <Descriptions.Item label={t('translation:fullName')}>
                {user?.firstName} {user?.lastName}
              </Descriptions.Item>
              <Descriptions.Item label={t('translation:citizensTable.province')}>{userCity}</Descriptions.Item>
              <Descriptions.Item label={t('ELO Puanı')}>{/*TODO API*/}</Descriptions.Item>
              <Descriptions.Item label={t('UKD Puanı')}>{/*TODO API*/}</Descriptions.Item>
              <Descriptions.Item label={t('translation:emailOnly')}>{user?.email}</Descriptions.Item>
              <Descriptions.Item label={t('translation:common.phone')}>{user?.phoneNumber}</Descriptions.Item>
            </Descriptions>
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => {
                if (values.acceptedKvkk)
                  onComplete?.({
                    tournamentId: tournament!.id,
                    identityNumber: user!.identityNumber,
                    categoryKey: values.categoryKey,
                  });
              }}
            >
              <Form.Item
                name="categoryKey"
                label={t('translation:category')}
                rules={[{ required: true, message: t('translation:forms.validationFormLabels.selectCategory') }]}
              >
                <Select size="small" options={categories} />
              </Form.Item>
              <Form.Item name="acceptedKvkk" valuePropName="checked">
                <Checkbox>
                  <Trans i18nKey="read-and-accept-instruction-and-kvkk">
                    0
                    <Link to={`/instruction-file/${tournament.instructionId}`} target="_blank" rel="noopener">
                      <Button type="link">1</Button>
                    </Link>
                    2
                  </Trans>
                </Checkbox>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <H4>{t('translation:kvkk-text')}</H4>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', maxHeight: '60vh', overflow: 'auto' }}
            >
              <Kvkk />
            </div>
          </div>
        ))}
    </Modal>
  );
};

export default TournamentRegistrationModal;

export const Kvkk = () => {
  return (
    <>
      <p>
        Türkiye Satranç Federasyonu olarak kişisel verileriniz konusunda büyük bir hassasiyet göstermekteyiz. Bu
        nedenle, temel hak ve özgürlüklerinizi korumak amacıyla yürürlükte olan 6698 Sayılı Kişisel Verilerin Korunması
        Kanunu’nu (“KVKK”) kapsamındaki kişiselverilerinizin kullanımı ve korunması ile ilgili haklarınız hakkında sizi
        bilgilendirmek istiyoruz.
      </p>
      <p>
        <strong>1. Kişisel Veri Nedir?</strong> Kişisel veri, KVKK ’da kimliği belirli veya belirlenebilir gerçek kişiye
        ilişkin her türlü bilgi olarak tanımlanmıştır. Buna göre bizimle paylaştığınız T.C Kimlik numaranız adınız,
        soyadınız, fotoğrafınız, elektronik posta adresiniz, telefon numaranız ve IBAN numaranız kişisel veri olarak
        adlandırılmaktadır.{' '}
      </p>
      <p>
        <strong>2. Kişisel verilerinizin tarafımızca toplanmasının amacı nedir?</strong> Türkiye Satranç Federasyonu ile
        olan ürün ve hizmet ilişkiniz dolayısıyla yasal yükümlülüklerimizi yerine getirebilmek ve sizi Federasyonumuz
        bünyesindeki gelişmelerden haberdar edebilmek için kişisel bilgilerinizi toplamakta ve bu kapsamda işlemekteyiz.
      </p>
      <p>
        <strong>3. Kişisel verilerinizi hangi amaçla, kimlere aktarıyoruz?</strong> Kişisel verileriniz Türkiye Satranç
        Federasyonu ile doğrudan/dolaylı yurtiçi/yurtdışı iştiraklerimizle, iş ilişkisinin devamı esnasında birlikte
        bizi temsil eden ve/veya faaliyetlerimizi yürütebilmek için işbirliği yaptığımız iş ortağımız olan kurum,
        kuruluşlarla, verilerin bulut ortamında saklanması hizmeti aldığımız yurtiçi/yurtdışı kişi ve kurumlarla
        paylaşılabilmektedir. Ayrıca, yasal yükümlülüklerimiz nedeniyle ve bunlarla sınırlı olmak üzere mahkemeler ve
        diğer kamu kurumları ile kişisel veriler paylaşılmaktadır.
      </p>
      <p>
        <strong>4. Kişisel verilerinizi nasıl saklıyoruz?</strong> Türkiye Satranç Federasyonu ile paylaşılan kişisel
        verileriniz ilgili yasal düzenlemelere, KVKK hükümlerine ve Türkiye Satranç Federasyonu standartlarına uygun
        olarak saklanmaktadır.
      </p>
      <p>
        <strong>5. Kişisel verilerinizi ne kadar süre ile tutuyoruz?</strong> Türkiye Satranç Federasyonu olarak kişisel
        verilerinizi KVKK’ ya uygun olarak saklamaktayız. KVKK md. 7/f.1.’e göre işlenmesi gerektiren amaç ortadan
        kalktığında ve/veya mevzuat uyarınca verilerinizi işlememiz için zorunlu kılındığımız zamanaşımı süreleri
        dolduğunda, kişisel verileriniz tarafımızca silinecek, yok edilecek veya anonimleştirerek kullanılmaya devam
        edilecektir.
      </p>
      <p>
        <strong>6. Kişisel Verilerin Korunması Kanunu’ndan doğan haklarınız nelerdir?</strong> İşlenen
        kişiselverilerinizle ilgili mevzuat uyarınca; kişisel veri işlenip işlenmediğini öğrenme, kişisel verileri
        işlenmişse buna ilişkin bilgi talep etme, kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
        kullanılmadığını öğrenme, yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,
        kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme, kişisel verilerin
        silinmesini veya yok edilmesini isteme, kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
        düzeltilmesine ve/veya kişisel verilerin silinmesini veya yok edilmesine ilişkin işlemlerin kişisel verilerin
        aktarıldığı üçüncü kişilere bildirilmesini isteme, işlenen verilerin münhasıran otomatik sistemler vasıtasıyla
        analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, kişisel verilerin
        kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarına
        sahipsiniz. Web sitemizde yer alan Başvuru Formu üzerinden yapılacak başvurularınıza yasal süresi içerisinde
        geri dönüş sağlamaktayız.{' '}
      </p>
    </>
  );
};
