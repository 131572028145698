import {
  ProposeRefereeForTournamentDTO,
  ExtendedTournamentModel,
  RegisterPlayerForTournamentDTO,
  TournamentReferee,
  ActiveTournamentModel,
  UnregisterPlayerForTournamentDTO,
  TournamentCategory,
  ActiveTournamentWithRefereeAssignmentInfoModel,
  TournamentPlayerTableData,
  TournamentUploadedFile,
  TournamentUploadedFileType,
  RefereeRating,
  SendRefereeRatingDTO,
  TournamentModel,
  TournamentNumberOfRoundsPerCategory,
} from '@app/domain/TournamentModel';
import { PaginatedResponse } from '@app/domain/PaginatedResponse';
import { httpApi2 } from '@app/api/http.api2';

export const getTournamentById = async (id: string): Promise<ExtendedTournamentModel> => {
  const response = await httpApi2.get<ExtendedTournamentModel[]>(`api/v1/tournament/find-by-id/${id}`);
  return response.data[0];
};

export const getTournamentsByCityId = async (cityPId: number): Promise<PaginatedResponse<TournamentModel>> => {
  const response = await httpApi2.get<PaginatedResponse<TournamentModel>>(
    `api/v1/tournament/search-by-city?cityPId=${cityPId}&maxRowsPerPage=1999999&page=1`,
  );
  return response.data;
};

export const getTournamentReferees = async (tournamentId: string, status?: number) => {
  const response = await httpApi2.get<PaginatedResponse<TournamentReferee>>('api/v1/tournament/referee', {
    params: { tournamentId, ...(status != null ? { status } : {}) },
  });
  const responseWithDummyPagination: PaginatedResponse<TournamentReferee> = {
    data: response.data.data,
    pagination: {
      maxRowsPerPage: 100,
      page: 1,
    },
    status: 200,
  };
  return responseWithDummyPagination;
};

export const getActiveTournamentsForPlayer = async (identityNumber: string) => {
  const response = await httpApi2.get<ActiveTournamentModel[]>('api/v1/tournament/activeplayers', {
    params: { identityNumber },
  });
  return response.data;
};

export const getPlayerAvailableCategoriesForTournament = async (identityNumber: string, tournamentId: string) => {
  const response = await httpApi2.post<TournamentCategory[]>('api/v1/tournament/playerCategories', {
    identityNumber,
    tournamentId,
  });
  return response.data;
};

export const registerPlayerForTournament = async (data: RegisterPlayerForTournamentDTO) => {
  const response = await httpApi2.post('api/v1/tournament/player', data);
  return response.data;
};

export const unregisterPlayerFromTournament = async (data: UnregisterPlayerForTournamentDTO) => {
  const response = await httpApi2.delete('api/v1/tournament/player', { data });
  return response.data;
};
export const proposeRefereeForTournament = async (data: ProposeRefereeForTournamentDTO) => {
  const response = await httpApi2.post('api/v1/tournament/propose-referee', data);
  return response.data;
};

export const cancelRefereeProposeForTournament = async (tournamentId: string, identityNumber: string) => {
  const response = await httpApi2.post(`api/v1/tournament/${tournamentId}/cancel-propose`, { identityNumber });
  return response.data;
};

export const cancelRefereeAssignmentToTournament = async (tournamentId: string, identityNumber: string) => {
  const response = await httpApi2.post(`api/v1/tournament/${tournamentId}/cancel-assignment`, { identityNumber });
  return response.data;
};

export const updateRefereeMission = async (tournamentRefereeId: number, missionId: number) => {
  const response = await httpApi2.post(`api/v1/tournament/referee/${tournamentRefereeId}/mission/${missionId}`);
  return response.data;
};

export const sendAssignmentsToRefereesForAcceptance = async (tournamentId: string) => {
  const response = await httpApi2.post(`api/v1/tournament/${tournamentId}/send-to-referee-for-acceptance`);
  return response.data;
};

export const completeTournamentRefereesAssignment = async (tournamentId: string) => {
  const response = await httpApi2.post(`api/v1/tournament/${tournamentId}/complete-referee-assignment`);
  return response.data;
};

export const getRefereeActiveTournamentsWithAssignmentsInfo = async (identityNumber: string) => {
  const response = await httpApi2.get<ActiveTournamentWithRefereeAssignmentInfoModel[]>(
    'api/v1/tournament/activereferees',
    {
      params: { identityNumber },
    },
  );
  return response.data;
};

export const acceptRefereeAssignmentToTournament = async (id: number) => {
  const response = await httpApi2.post(`api/v1/tournament/referee/${id}/accept`);
  return response.data;
};

export const declineRefereeAssignmentToTournament = async (id: number) => {
  const response = await httpApi2.post(`api/v1/tournament/referee/${id}/reject`);
  return response.data;
};

export const updateTournament = (entity: ExtendedTournamentModel) => httpApi2.put('api/v1/tournament', entity);

export const deleteTournament = (id: string) => httpApi2.delete(`api/v1/tournament/${id}`);

// Tournament operations APIs

export const getRefereeAssignedStartedTournaments = async (identityNumber: string, tournamentName?: string) => {
  const response = await httpApi2.get<ActiveTournamentModel[]>('api/v1/tournament/started', {
    params: { identityNumber, tournamentName },
  });
  return response.data;
};

export const getUkdTournamentList = async (tournamentName?: string) => {
  const response = await httpApi2.get<ActiveTournamentModel[]>('api/v1/tournament/list-for-ukd', {
    params: { tournamentName },
  });
  return response.data;
};

export const getStartedTournamentPlayerTableList = async (tournamentId: string) => {
  const response = await httpApi2.get<TournamentPlayerTableData[]>(`api/v1/tournament/${tournamentId}/download-player`);
  return response.data;
};

export const getTournamentUploadedFiles = async (search?: {
  tournamentId?: string;
  category?: string;
  type?: TournamentUploadedFileType;
}) => {
  const response = await httpApi2.get<TournamentUploadedFile[]>(`api/v1/tournament-file/search`, { params: search });
  return response.data;
};

export const uploadTournamentTourFile = async (
  tournamentId: string,
  category: string,
  type: TournamentUploadedFileType,
  data: Record<string, any>[],
) => {
  const response = await httpApi2.post(`api/v1/tournament/${tournamentId}/upload-tour-file`, data, {
    params: { category, type },
  });
  return response.data;
};

export const getTournamentUploadedFilesWithFileData = async (search?: {
  tournamentId?: string;
  category?: string;
  type?: TournamentUploadedFileType;
}) => {
  const response = await httpApi2.get<TournamentUploadedFile[]>(`api/v1/tournament-file/search-with-data`, {
    params: search,
  });
  return response.data;
};

export const uploadTournamentCrossTableFile = async (
  tournamentId: string,
  category: string,
  data: Record<string, any>[],
) => {
  const response = await httpApi2.post(`api/v1/tournament/${tournamentId}/upload-cross-file`, data, {
    params: { category },
  });
  return response.data;
};

export const getTournamentRefereeRatings = async (search?: {
  tournamentId?: string;
  raterMissionPId?: number;
  raterIdentityNumber?: string;
  ratedIdentityNumber?: string;
}) => {
  const response = await httpApi2.get<RefereeRating[]>(`api/v1/tournament-referee-report/search`, { params: search });
  return response.data;
};

export const getTournamentNumberOfRoundsPerCategory = async (tournamentId: string) => {
  const response = await httpApi2.get<TournamentNumberOfRoundsPerCategory[]>(
    `api/v1/tournament/${tournamentId}/number-of-rounds`,
  );
  return response.data;
};

export const sendRefereeRating = async (data: SendRefereeRatingDTO) => {
  const response = await httpApi2.post<RefereeRating>(`api/v1/tournament-referee-report`, data);
  return response.data;
};

export const updatedTournamentChiefRefereeComment = async (tournamentId: string, comment: string) => {
  const response = await httpApi2.put(`api/v1/tournament/${tournamentId}/chief-referee-comment`, { comment });
  return response.data;
};

export const updatedTournamentObserverRefereeComment = async (tournamentId: string, comment: string) => {
  const response = await httpApi2.put(`api/v1/tournament/${tournamentId}/observer-referee-comment`, { comment });
  return response.data;
};

export const updateTournamentCategoryNumberOfRounds = async (
  tournamentId: string,
  category: string,
  numberOfRounds: number,
) => {
  const response = await httpApi2.put(`api/v1/tournament/${tournamentId}/number-of-rounds`, {
    category,
    numberOfRounds,
  });
  return response.data;
};

export const closeTournament = async (tournamentId: string) => {
  const response = await httpApi2.put(`api/v1/tournament/${tournamentId}/close`);
  return response.data;
};
