import { CitizensModel } from '@app/domain/CitizensModel';
import { PaginationModel } from '@app/domain/PaginationModel';
import { AxiosResponse } from 'axios';
import { httpApi } from './http.api';

export type PaginatedCitizensRespone = {
  data: CitizensModel[];
  pagination: PaginationModel;
  status: number;
  message?: string;
};

export const getCitizens = (pagination: PaginationModel): Promise<AxiosResponse<PaginatedCitizensRespone>> =>
  httpApi.get('api/v1/citizens', {
    params: { ...pagination },
  });

export const searchCitizens = (
  identityNumber: string,
  pagination: PaginationModel,
): Promise<AxiosResponse<PaginatedCitizensRespone>> =>
  httpApi.get('api/v1/citizens/search', {
    params: { identityNumber, ...pagination },
  });

export const getByCitizensIdentityNumber = (identityNumber: string) => httpApi.get(`api/v1/citizens/${identityNumber}`);

export const getCitizensByDepartmentIds = (ids: number[]) =>
  httpApi.post(`api/v1/citizens/getbydepartmentids`, {
    ids,
  });

export const getLicensesByIdentityNumber = (identityNumber: string) =>
  httpApi.get(`api/v1/citizens/getlicenses/${identityNumber}`);

export const insertCitizens = (entity: CitizensModel) => httpApi.post('api/v1/citizens', entity);

export const updateCitizens = (entity: CitizensModel) => httpApi.put('api/v1/citizens', entity);

export const deleteCitizens = (identityNumber: string) => httpApi.delete(`api/v1/citizens/${identityNumber}`);
