import { InstructionModel } from '@app/domain/InstructionModel';
import { PaginatedResponse } from '@app/domain/PaginatedResponse';
import { PaginationModel } from '@app/domain/PaginationModel';
import { AxiosResponse } from 'axios';
import { httpApi } from './http.api';

const URI = 'api/v1/instruction';

export const getInstructions = (
  pagination: PaginationModel,
): Promise<AxiosResponse<PaginatedResponse<InstructionModel>>> => {
  return httpApi.get(URI, {
    params: { ...pagination },
  });
};

export const searchInstructions = (
  instructionName: string,
  pagination: PaginationModel,
): Promise<AxiosResponse<PaginatedResponse<InstructionModel>>> =>
  httpApi.get(`${URI}/search`, {
    params: { instructionName, ...pagination },
  });

export const getByInstructionId = (id: number) => httpApi.get(`api/v1/instruction/${id}`);

export const insertInstructions = (entity: InstructionModel) => httpApi.post(URI, entity);

export const updateInstructions = (entity: InstructionModel) => httpApi.put(URI, entity);

export const approveInstruction = (id: string) => httpApi.put(`${URI}/approve/${id}`);
export const sendForApproveInstruction = (id: string) => httpApi.put(`${URI}/sendforapprove/${id}`);
export const requestRevisionInstruction = (id: string, body: { reason: string }) =>
  httpApi.put(`${URI}/requestrevision/${id}`, body);
export const publishInstruction = (id: string, body: { image: string; description: string }) =>
  httpApi.post(`${URI}/publish/${id}`, body);

export const deleteInstructions = (id: string) => httpApi.delete(`${URI}/${id}`);

export const getAllInstructions = () => httpApi.get(`${URI}/getallinstructions`);
export const getAllApprovedInstructions = () => httpApi.get(`${URI}/approved`);
