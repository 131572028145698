import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './NewUserForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { getByCitizensIdentityNumber } from '@app/api/citiziens.api';
import { createPlayer } from '@app/api/user.api';
import { UserModel } from '@app/domain/UserModel';

interface NewUserRegisterFormData {
  identityNumber: string;
  name: string;
  lastName: string;
  fatherName: string;
  email: string;
  mobile: string;
}

export const NewUserRegisterForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState<NewUserRegisterFormData>({
    identityNumber: '',
    name: '',
    lastName: '',
    fatherName: '',
    email: '',
    mobile: '',
  });

  const location = useLocation();
  const identityNumber = (location.state as { identityNumber: string })?.identityNumber;

  const handleSubmit = async (values: NewUserRegisterFormData) => {
    setLoading(true);

    try {
      const response = await createPlayer(values);

      if (response.data.statusCode === 201) {
        notificationController.success({
          message: t(response.data.message),
        });
        setTimeout(() => {
          navigate('/auth/login');
        }, 2000);
      } else if (response.status === 400) {
        notificationController.error({ message: 'User already exists' });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCitizenData = async () => {
      try {
        const response = await getByCitizensIdentityNumber(identityNumber || '');
        const { data } = response;
        setFormData(data);
      } catch (error) {
        console.error(error);
      }
    };

    if (identityNumber) {
      fetchCitizenData();
    } else {
      navigate('/auth/login'); // Redirect to login if identityNumber is not available
    }
  }, [identityNumber, navigate]);
  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={formData}>
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('common.newUser')}</Auth.FormTitle>
        <S.Description>{t('newUserRegister.description')}</S.Description>
        <Auth.FormItem
          name="identityNumber"
          label={t('citizensTable.identificationNumber')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'string',
              message: t('common.notValididentityNumber'),
            },
          ]}
          normalize={(val, prevVal) => (new RegExp(/^[0-9]*$/).test(val) ? val : prevVal)}
        >
          <Auth.FormInput placeholder={t('common.identityNumber')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="name"
          label={t('citizensTable.name')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('citizensTable.name')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="lastName"
          label={t('citizensTable.surname')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('citizensTable.surname')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="fatherName"
          label={t('citizensTable.fatherName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('citizensTable.fatherName')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="email"
          label={t('citizensTable.mailAddress')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('citizensTable.mailAddress')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="mobile"
          label={t('citizensTable.mobilePhone')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'string',
              message: t('common.notValidPhoneNumber'),
            },
          ]}
          normalize={(val, prevVal) => (new RegExp(/^[0-9]*$/).test(val) ? val : prevVal)}
        >
          <Auth.FormInput placeholder={t('citizensTable.mobilePhone')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('newUserRegister.sendInstructions')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
