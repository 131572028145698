import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import useUser from '@app/hooks/useUser';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);
  const location = useLocation();
  const loggedInUser = JSON.parse(localStorage.getItem('user') as any);
  // console.log('location', loggedInUser);
  // if (loggedInUser && !loggedInUser?.lastLogin) return <Navigate to="/auth/new-password" replace />;
  return loggedInUser && token ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
