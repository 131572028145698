import React, { useMemo } from 'react';
import { ActiveTournamentModel } from '@app/domain/TournamentModel';
import { Text as H5 } from '@app/components/common/typography/H5/H5.styles';
import logo from 'assets/logo.png';
import { Card } from '@app/components/common/Card/Card';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import useParameters from '@app/hooks/useParameters';
import { Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ActiveTournamentCard = ({
  tournament,
  onRegisterForTournament,
  onUnregisterFromTournament,
}: {
  tournament: ActiveTournamentModel;
  onRegisterForTournament?: (tournament: ActiveTournamentModel) => void;
  onUnregisterFromTournament?: (tournament: ActiveTournamentModel) => void;
}) => {
  const { t, i18n } = useTranslation();

  const parameterCities = useParameters('ILLER');
  const parameterDistricts = useParameters('ILCELER');
  const parameterTournamentTypes = useParameters('TurnuvaTipi');
  const parameterRoundCountTypes = useParameters('TurnuvaTur');

  const city = useMemo(() => {
    return parameterCities.parameterGroup.find((city) => city.id == tournament.cityPId)?.name;
  }, [parameterCities, tournament.cityPId]);

  const district = useMemo(() => {
    return parameterDistricts.parameterGroup.find((district) => district.id == tournament.countyPId)?.name;
  }, [parameterCities, tournament.countyPId]);

  const tournamentType = useMemo(() => {
    return parameterTournamentTypes.parameterGroup.find((type) => type.id == tournament.typePId)?.name;
  }, [parameterCities, tournament.typePId]);

  const tournamentRoundCount = useMemo(() => {
    return parameterRoundCountTypes.parameterGroup.find(
      (roundCountType) => roundCountType.id == tournament.numberOfRoundsPId,
    )?.name;
  }, [parameterRoundCountTypes, tournament.numberOfRoundsPId]);

  const startDate = new Date(tournament.startDate);
  const endDate = new Date(tournament.endDate);

  return (
    <Card padding={20}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <H5>{tournament.name}</H5>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <img
            src={
              // TODO Api tournament image ?
              logo
            }
            width={75}
            height={75}
            alt="tournament image"
          />
          <div style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap', flexGrow: 1 }}>
            <Descriptions layout="horizontal" size="small" column={{ xs: 1, sm: 2 }} style={{ maxWidth: '800px' }}>
              <Descriptions.Item
                label={`${t('translation:citizensTable.province')}/${t('translation:citizensTable.district')}`}
              >
                {city}/{district}
              </Descriptions.Item>
              <Descriptions.Item label={t('translation:tournament.tournamentType')}>{tournamentType}</Descriptions.Item>
              <Descriptions.Item label={t('translation:date')}>
                {startDate.toLocaleDateString(i18n.language)} - {endDate.toLocaleDateString(i18n.language)}
              </Descriptions.Item>
              <Descriptions.Item label={t('translation:round-count')}>{tournamentRoundCount}</Descriptions.Item>
              <Descriptions.Item label={t('translation:quota')}>
                {tournament.quota + ' / ' + tournament.registeredPlayerCount}
              </Descriptions.Item>
            </Descriptions>
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                alignSelf: 'end',
                justifyContent: 'end',
                marginBottom: '0.5rem',
                flexGrow: 1,
              }}
            >
              <Link to={`/instruction-file/${tournament.instructionId}`} target="_blank" rel="noopener">
                <Button size="small">{t('translation:go-to-instruction')}</Button>
              </Link>
              {tournament.identityNumber ? (
                <Button
                  type="ghost"
                  danger={true}
                  size="small"
                  onClick={() => onUnregisterFromTournament?.(tournament)}
                >
                  {t('translation:getOut')}
                </Button>
              ) : (
                <Button type="primary" size="small" onClick={() => onRegisterForTournament?.(tournament)}>
                  {t('translation:join')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ActiveTournamentCard;
