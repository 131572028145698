import { getByInstructionId, getInstructions } from '@app/api/instruction.api';
import { getByCitizensIdentityNumber } from '@app/api/citiziens.api';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './form1.css';

import { useState } from 'react';
import { Spinner } from '../common/Spinner/Spinner';
import useParameters from '@app/hooks/useParameters';
import { PageTitle } from '../common/PageTitle/PageTitle';
import { groupBy, keys } from 'lodash';
import { getAllParameters } from '@app/api/parameter.api';
import { Button } from '../common/buttons/Button/Button';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

function InstructionFile() {
  const { id } = useParams();

  const [data, setData] = useState({ data: null, loading: true });
  const [parameterGroup, setParameterGroup] = useState([]);
  const terms = useParameters('YonergeTermMadde');
  const [coordinator, setCoordinator] = useState(null);
  const [director, setDirector] = useState(null);
  const [program, setProgram] = useState();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getAllParameters().then((res) => {
      setParameterGroup(res.data);
      localStorage.setItem('parameters', JSON.stringify(res.data));
      getByInstructionId(id).then((resx) => {
        console.log(resx.data);

        if (resx.data.data.prize?.prizeTableData?.length > 0) {
          resx.data.data.prize.columns.map((r) => {
            r.prizeValues = resx.data.data.prize.prizeTableData.map((p) => {
              return p[r.dataIndex] || null;
            });
            r.render = r.prizeValues.filter((p) => p === null).length !== r.prizeValues.length;
            return r;
          });
        }
        resx.data.data.prize.tablePrizes = Array.from(Array(resx.data.data.prize.prizeTableData.length).keys()).map(
          (r) => {
            return resx.data.data.prize.columns.map((p) => {
              return p.render && p.prizeValues[r];
            });
          },
        );
        console.log(resx.data.data.prize.taplePrizes, 'tableprizes');
        if (resx.data.data.coordinator) {
          getByCitizensIdentityNumber(resx.data.data.coordinator).then((rest) => setCoordinator(rest.data));
        }
        if (resx.data.data.director) {
          getByCitizensIdentityNumber(resx.data.data.director).then((rest) => setDirector(rest.data));
        }
        setProgram(groupBy(resx.data.data.program, 'scheduleDate'));
        setData({ data: { ...resx.data.data }, loading: false });
      });
    });
  }, [id]);

  if (data.loading) {
    return <Spinner />;
  }
  console.log(data.data.prize.taplePrizes, 'tableprizes');

  const ActualInstruction = () => (
    <div style={{ padding: 10 }}>
      <PageTitle>{data.data.name}</PageTitle>
      <div className="container">
        <div className="navbar">
          <div className="logo" style={{ textAlign: 'center' }}>
            <b style={{ textAlign: 'center' }}>{data.data.name.toLocaleUpperCase()}</b>
          </div>
        </div>
        <table className="instructionTable" border="1" style={{ marginBottom: 10 }}>
          <thead>
            <tr>
              <th className="deneme">İLİ</th>
              <th style={{ textAlign: 'left', paddingLeft: 5 }}>
                {parameterGroup.find((p) => p.id === data.data.cityPId).name.toLocaleUpperCase()}
              </th>
              <th className="deneme">İLÇESİ</th>
              <th style={{ textAlign: 'left', paddingLeft: 5 }}>
                {parameterGroup.find((p) => p.id === data.data.countyPId)?.name.toLocaleUpperCase()}
              </th>
            </tr>
            <tr>
              <th className="deneme">YARIŞMA ADI</th>
              <th colSpan="6" style={{ textAlign: 'start', paddingLeft: 5 }}>
                {data.data.name.toLocaleUpperCase()}
              </th>
            </tr>
            <tr>
              <th className="deneme">BAŞLAMA-BİTİŞ TARİHİ</th>
              <th style={{ textAlign: 'left', paddingLeft: 5 }}>
                {new Date(data.data.startDate).toLocaleDateString('tr-TR')}
                <br />
                {new Date(data.data.endDate).toLocaleDateString('tr-TR')}
              </th>
              <th className="deneme">YERİ</th>
              <th style={{ textAlign: 'left', paddingLeft: 5 }}>{data.data.location.toLocaleUpperCase()}</th>
            </tr>
            <tr>
              <th className="deneme">SON BAŞVURU TARİHİ</th>
              <th style={{ textAlign: 'left', paddingLeft: 5 }}>
                {' '}
                {new Date(new Date(data.data.registrationDue).setSeconds(0)).toLocaleString('tr-TR').substring(0, 16)}
              </th>
              <th className="deneme">SİSTEM</th>
              <th style={{ textAlign: 'left', paddingLeft: 5 }}>
                {parameterGroup.find((p) => p.id === data.data.typePId).name.toLocaleUpperCase()} {' / '}{' '}
                {parameterGroup.find((p) => p.id === data.data.numberOfRoundsPId)?.name.toLocaleUpperCase() ?? ''} TUR
              </th>
            </tr>
            <tr>
              <th className="deneme">DÜŞÜNME SÜRESİ</th>
              <th colSpan="6" style={{ textAlign: 'left', paddingLeft: 5 }}>
                {parameterGroup.find((p) => p.id === data.data.timeTempoPId).name.toLocaleUpperCase()}
              </th>
            </tr>
            <tr>
              <th className="deneme">ORGANİZASYON</th>
              <th colSpan="6" style={{ textAlign: 'left', paddingLeft: 5 }}>
                {data.data.organizationName.toLocaleUpperCase()}
              </th>
            </tr>
          </thead>
        </table>
        <div className="center">
          {terms.parameterGroup.map((r) => {
            return (
              <>
                <h4 className="title" style={{ paddingBottom: 25, paddingLeft: 10 }}>
                  {r.name.substring(0, r.name.length - 6)}
                </h4>
                <br></br>
                {r.bigData
                  ? JSON.parse(r.bigData).map((big) => {
                      if (big.order === '2.2') {
                        return (
                          <>
                            <p>{big.description.replace('….', data.data.category.length)}</p>
                            <table>
                              <thead>
                                <tr style={{ backgroundColor: '#d2eaf1', border: '1px black' }}>
                                  <th style={{ border: '1px solid black' }}>KATEGORİ ADI</th>
                                  <th style={{ border: '1px solid black' }}>KATEGORİ KRİTERİ</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.data.category.map((r, id) => {
                                  const currentYear = new Date().getFullYear();
                                  const ukdCriteria =
                                    (r.minUkd ? `${r.minUkd}` : '') +
                                    (r.maxUkd ? ` ${r.minUkd ? '-' : ''} ${r.maxUkd} ` : ' ') +
                                    (r.minUkd || r.maxUkd
                                      ? `${
                                          data.data.tempoPId === 45
                                            ? 'UHS'
                                            : data.data.tempoPId === '47'
                                            ? 'UYS'
                                            : 'UKD'
                                        } Puanı `
                                      : '');
                                  const eloCriteria =
                                    (r.minElo ? `${r.minElo}` : '') +
                                    (r.maxElo ? ` ${r.minElo ? '-' : ''} ${r.maxElo} ` : ' ') +
                                    (r.minElo || r.maxElo ? 'ELO Puanı' : '');
                                  const ageCriteria =
                                    (r.minAge ? `${r.minAge < 1000 ? currentYear - r.minAge : r.minAge}` : '') +
                                    (r.maxAge
                                      ? `${r.minAge ? '-' : ''} ${r.maxAge < 1000 ? currentYear - r.maxAge : r.maxAge}`
                                      : '') +
                                    (r.minAge || r.maxAge ? ' Aralığında Doğan Sporcular' : '');

                                  const sameAge =
                                    r.minAge && r.maxAge
                                      ? r.minAge === r.maxAge
                                        ? `${
                                            r.mingAge < 1000 ? currentYear - r.minAge : r.minAge
                                          } Yılı Doğumlu Sporcular`
                                        : ''
                                      : '';

                                  return (
                                    <tr key={`${id}-category-key`}>
                                      <td className="th-td">{r.name}</td>
                                      <td className="th-td">
                                        {ukdCriteria + eloCriteria + (sameAge ? sameAge : ageCriteria)}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </>
                        );
                      } else if (big.order === '2.7') {
                        return (
                          <>
                            <p>{big.description}</p>
                            {data.data.term.map && data.data.term.length > 0
                              ? data.data.term.map((t, i) => (
                                  <p key={t.key + '-extra-term'}>
                                    {`2.${8 + i}`} {t.term}
                                  </p>
                                ))
                              : ''}
                          </>
                        );
                      } else if (big.order === '5.1') {
                        return (
                          <>
                            <p>5.1 &nbsp; {data.data.prize.prizeText ? data.data.prize.prizeText : 'aslkdfjaşlsdf '}</p>
                            {data.data.prize.tablePrizes.filter((p) => {
                              console.log(p);
                              return p.filter((r) => r === false).length !== p.length;
                            }).length > 0 &&
                            data.data.prize.columns &&
                            data.data.prize.prizeTableData ? (
                              <table style={{ marginBottom: 10 }}>
                                <thead className="deneme2">
                                  <tr>
                                    <th colSpan={data.data.prize.columns.length + 1}>Ödül Tablosu</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr style={{ backgroundColor: '#d2eaf1', border: '1px black' }}>
                                    <th style={{ border: '1px solid black' }}>Derece</th>
                                    {data.data.prize.columns.map((r) => {
                                      return (
                                        r.render && (
                                          <th key={r.key} style={{ border: '1px solid black' }}>
                                            {r.title}
                                          </th>
                                        )
                                      );
                                    })}
                                  </tr>
                                  <>
                                    {data.data.prize.tablePrizes.map((r, index) => {
                                      console.log(data.data.prize.tablePrizes);
                                      return (
                                        r.filter((p) => p === null).length !== r.length - 1 && (
                                          <tr key={index}>
                                            <td style={{ border: '1px solid black' }}>{index + 1}</td>
                                            {r.map(
                                              (p) => p !== false && <td style={{ border: '1px solid black' }}>{p}</td>,
                                            )}{' '}
                                          </tr>
                                        )
                                      );
                                      // const keyss = Object.keys(r);
                                      // return (
                                      //   <tr key={r.key} style={{ border: '1px black' }}>
                                      //     <td style={{ border: '1px solid black' }}>{r.key}</td>
                                      //     {keyss
                                      //       .filter((k) => k !== 'key')
                                      //       .map((keysss) => (
                                      //         <td key={keysss} style={{ border: '1px solid black' }}>
                                      //           {r[keysss]}
                                      //         </td>
                                      //       ))}
                                      //   </tr>
                                      // );
                                    })}
                                  </>
                                </tbody>
                              </table>
                            ) : (
                              ''
                            )}
                          </>
                        );
                      } else if (big.order === '7.5') {
                        const bigDescription =
                          data.data.tempoPId === 46 ? big.description.replace('7.5', '7.4') : big.description;
                        return <p>{bigDescription}</p>;
                      } else if (big.order === '7.6') {
                        const bigDescription =
                          data.data.tempoPId === 46 ? big.description.replace('7.6', '7.5') : big.description;
                        return <p>{bigDescription}</p>;
                      } else if (big.order === '7.7') {
                        const bigDescription =
                          data.data.tempoPId === 46 ? big.description.replace('7.7', '7.6') : big.description;
                        return (
                          <p>
                            {bigDescription.replace(
                              '{{city}}',
                              parameterGroup.find((p) => p.id === data.data.cityPId).name.toLowerCase(),
                            )}
                          </p>
                        );
                      } else if (big.order === '7.8') {
                        const bigDescription =
                          data.data.tempoPId === 46 ? big.description.replace('7.8', '7.7') : big.description;
                        return (
                          <p>
                            {bigDescription
                              .replace(
                                '{{city}}',
                                parameterGroup.find((p) => p.id === data.data.cityPId).name.toLowerCase(),
                              )
                              .replace(
                                'UKD',
                                data.data.tempoPId === 45 ? 'UHS' : data.data.tempoPId === 46 ? 'UKD' : 'UYS',
                              )}
                          </p>
                        );
                      } else if (big.order === '7.10') {
                        const bigDescription =
                          data.data.tempoPId === 46 ? big.description.replace('7.10', '7.9') : big.description;
                        return (
                          <p>
                            {bigDescription.replace(
                              '{{city}}',
                              parameterGroup.find((p) => p.id === data.data.cityPId).name.toLowerCase(),
                            )}
                          </p>
                        );
                      } else if ((data.data.tempoPId === 47 || data.data.tempoPId === 45) && big.order === '7.4') {
                        return (
                          <p>
                            {big.description
                              .replace('{{type}}', parameterGroup.find((p) => p.id === data.data.tempoPId).name)
                              .replace('Ek B-4', data.data.tempoPId === 47 ? 'EK B-4' : 'EK A-4')}
                          </p>
                        );
                      } else if (big.order === '7.9') {
                        const bigDescription =
                          data.data.tempoPId === 46 ? big.description.replace('7.9', '7.8') : big.description;
                        return (
                          <p>
                            {bigDescription
                              .replace('{{type}}', parameterGroup.find((p) => p.id === data.data.tempoPId).name)
                              .replace(
                                'UKD/ELO listesi kullanılacaktır',
                                data.data.tempoPId === 45
                                  ? `UHS/ELO ${
                                      data.data.calculation.toLowerCase().includes('değil')
                                        ? 'hesaplamalarında kullanılmayacaktır'
                                        : 'listesi kullanılacaktır'
                                    }`
                                  : data.data.tempoPId === 46
                                  ? `UKD/ELO ${
                                      data.data.calculation.toLowerCase().includes('değil')
                                        ? 'hesaplamalarında kullanılmayacaktır'
                                        : 'listesi kullanılacaktır'
                                    }`
                                  : `UYS/ELO ${
                                      data.data.calculation.toLowerCase().includes('değil')
                                        ? 'hesaplamalarında kullanılmayacaktır'
                                        : 'listesi kullanılacaktır'
                                    }`,
                              )}
                          </p>
                        );
                      } else if (big.order === '2.3') {
                        return (
                          <p>
                            {big.description.replace(
                              '{{city}}',
                              parameterGroup.find((p) => p.id === data.data.cityPId).name.toLowerCase(),
                            )}
                          </p>
                        );
                      } else if (big.order === '4.1') {
                        return (
                          <p>
                            {big.description
                              .replace('{{type}}', parameterGroup.find((p) => p.id === data.data.tempoPId).name)
                              .replace(
                                '{{calculation}} hesaplamalarında kullanılacaktır',
                                data.data.calculation.toLowerCase().includes('değildir')
                                  ? ' hesaplaması yapılmayacaktır'
                                  : data.data.calculation + ' hesaplamalarında kullanılacaktır',
                              )}
                          </p>
                        );
                      } else if (big.order === '9.') {
                        return (
                          <table>
                            <thead className="deneme2">
                              <tr>
                                <th colSpan="3">TURNUVA PROGRAMI</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: '#d2eaf1', border: '1px black' }}>
                                <th style={{ border: '1px solid black', width: 150 }}>Tarih</th>
                                <th style={{ border: '1px solid black' }}>Saat</th>
                                <th style={{ border: '1px solid black' }}>Açıklama</th>
                              </tr>
                              {program &&
                                Object.keys(program).length > 0 &&
                                Object.keys(program).map((p, i) => {
                                  const options = {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  };
                                  console.log(p);
                                  let convertedDate = null;
                                  if (p.includes('.')) {
                                    convertedDate = new Date(p.split('.')[2], p.split('.')[1], p.split('.')[0]);
                                  }
                                  return (
                                    <>
                                      <tr key={`${id}-prg-key`}>
                                        <td className="th-td" rowSpan={program[p].length + 1}>
                                          {new Date(convertedDate ?? p).toLocaleDateString('tr-TR', options)}
                                        </td>
                                      </tr>
                                      {program[p].map((r) => {
                                        return (
                                          <tr key={p}>
                                            <td className="th-td">{r.hour}</td>
                                            <td className="th-td">{r.description || ' '}</td>
                                          </tr>
                                        );
                                      })}
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>
                        );
                      } else if (big.order === '7.2') {
                        return (
                          <p>
                            {big.description.replace(
                              '{{periodOfLose}}',
                              data.data.periodOfLose ? data.data.periodOfLose + ' dakikadır' : 'belirlenmemiştir.',
                            )}
                          </p>
                        );
                      } else if (big.order === '8.1') {
                        return (
                          <>
                            <div style={{ display: 'flex' }}>
                              <p style={{ flex: 2 }}>
                                8.1 Turnuva Direktörü: {`${director ? director.name + ' ' + director.lastName : ''}`}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </p>
                              <p style={{ flex: 1 }}>Telefon Numarası: {director?.mobile}</p>
                            </div>
                            {coordinator ? (
                              <div style={{ display: 'flex' }}>
                                <p style={{ flex: 2 }}>
                                  Turnuva Koordinatörü:{' '}
                                  {`${coordinator ? coordinator.name + ' ' + coordinator.lastName : ''}`}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </p>
                                <p style={{ flex: 1 }}>Telefon Numarası: {coordinator?.mobile}</p>
                              </div>
                            ) : null}
                          </>
                        );
                      } else if (big.order !== '7.4') {
                        return <p>{big.description}</p>;
                      }
                    })
                  : null}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Button onClick={handlePrint}>PDF Çıktısı Al</Button>
      <div ref={componentRef}>
        <ActualInstruction />
      </div>
    </>
  );
}

export default InstructionFile;
