import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { NewUserRegisterForm } from '@app/components/auth/NewUserRegisterForm/NewUserRegisterForm';

const NewUserRegisterPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.newUser')}</PageTitle>
      <NewUserRegisterForm />
    </>
  );
};

export default NewUserRegisterPage;
