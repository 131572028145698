import { UserModel } from '@app/domain/UserModel';
import { ParameterModel } from '@app/domain/ParameterModel';

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const readToken = (): string | null => {
  return localStorage.getItem('accessToken');
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : null;
};

export const readParameters = (): ParameterModel[] | null => {
  const paramsString = localStorage.getItem('parameters');
  return paramsString ? (JSON.parse(paramsString) as ParameterModel[]) : null;
};

export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');
