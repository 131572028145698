import React, { useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Appcrumb } from '@app/components/appcrumb/Appcrumb';
import { useTranslation } from 'react-i18next';
import useUser from '@app/hooks/useUser';
import PlayerDashboard from '@app/pages/home/PlayerDashboard';
import RefereeDashboard from '@app/pages/home/RefereeDashboard';
import { Tabs } from 'antd';

const HomePage = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [activeTab, setActiveTab] = useState('none');

  useEffect(() => {
    setActiveTab(user?.isPlayer ? 'player' : user?.isReferee ? 'referee' : 'none');
  }, [user]);

  return (
    <>
      <PageTitle>{t('translation:breadcrumbs.home')}</PageTitle>
      <div style={{ marginTop: 15 }}>
        <Appcrumb />
        {user && (
          <Tabs style={{ marginLeft: '2%', marginRight: '2%' }} activeKey={activeTab} onChange={setActiveTab}>
            <Tabs.TabPane disabled={!user.isPlayer} tab={t('translation:player')} key="player">
              <PlayerDashboard />
            </Tabs.TabPane>
            <Tabs.TabPane disabled={!user.isReferee} tab={t('translation:referee')} key="referee">
              <RefereeDashboard />
            </Tabs.TabPane>
          </Tabs>
        )}
      </div>
    </>
  );
};

export default HomePage;
