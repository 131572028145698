import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUser from '@app/hooks/useUser';
import {
  ActiveTournamentModel,
  RegisterPlayerForTournamentDTO,
  UnregisterPlayerForTournamentDTO,
} from '@app/domain/TournamentModel';
import { Text as H4 } from '@app/components/common/typography/H4/H4.styles';
import {
  getActiveTournamentsForPlayer,
  registerPlayerForTournament,
  unregisterPlayerFromTournament,
} from '@app/api/tournament.api';
import { notificationController } from '@app/controllers/notificationController';
import ActiveTournamentCard from '@app/components/tournament/ActiveTournamentCard';
import TournamentRegistrationModal from '@app/components/tournament/modal/TournamentRegistrationModal';
import { Select } from '@app/components/common/selects/Select/Select';
import Search from 'antd/lib/input/Search';
import TournamentRegistrationCancelModal from '@app/components/tournament/modal/TournamentRegistrationCancelModal';

const PlayerDashboard = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [refreshDataCount, setRefreshDataCount] = useState(0);

  const [activeTournaments, setActiveTournaments] = useState<ActiveTournamentModel[]>([]);

  const [selectedTournamentToRegister, setSelectedTournamentToRegister] = useState<ActiveTournamentModel>();
  const [selectedTournamentToUnregister, setSelectedTournamentToUnregister] = useState<ActiveTournamentModel>();

  useEffect(() => {
    if (user?.identityNumber) {
      getActiveTournamentsForPlayer(user.identityNumber).then(setActiveTournaments);
    }
  }, [user?.identityNumber, refreshDataCount]);

  const onRegisterForTournament = (data: RegisterPlayerForTournamentDTO) => {
    registerPlayerForTournament(data).then(() => {
      setSelectedTournamentToRegister(undefined);
      setRefreshDataCount((refData) => refData + 1);
      notificationController.success({ message: t('translation:common.registrationSuccess') });
    });
  };

  const onUnregisterFromTournament = (data: UnregisterPlayerForTournamentDTO) => {
    if (user?.identityNumber) {
      unregisterPlayerFromTournament(data).then(() => {
        setSelectedTournamentToUnregister(undefined);
        setRefreshDataCount((refData) => refData + 1);
        notificationController.success({ message: t('translation:common.success') });
      });
    }
  };

  return (
    <div>
      <H4 style={{ marginBottom: '2rem' }}>{t('tournament.activeTournaments')}</H4>
      <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', marginBottom: '2rem' }}>
        <Select size="small" disabled placeholder={t('translation:select-province')} />
        <Search size="small" disabled style={{ maxWidth: '400px' }} placeholder={t('translation:header.search')} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {activeTournaments.map((item, index) => (
          <ActiveTournamentCard
            key={index}
            tournament={item}
            onRegisterForTournament={setSelectedTournamentToRegister}
            onUnregisterFromTournament={setSelectedTournamentToUnregister}
          />
        ))}
      </div>
      <TournamentRegistrationModal
        tournament={selectedTournamentToRegister}
        onCloseRequest={() => setSelectedTournamentToRegister(undefined)}
        onComplete={onRegisterForTournament}
      />
      <TournamentRegistrationCancelModal
        tournament={selectedTournamentToUnregister}
        onCloseRequest={() => setSelectedTournamentToUnregister(undefined)}
        onComplete={onUnregisterFromTournament}
      />
    </div>
  );
};

export default PlayerDashboard;
