import { UserModel } from '@app/domain/UserModel';
import { useState, useEffect } from 'react';

export default function useUser() {
  const loggedInUser = localStorage.getItem('user');
  const [user, setUser] = useState<UserModel>();

  useEffect(() => {
    if (loggedInUser) {
      setUser(JSON.parse(loggedInUser) as UserModel);
    }
  }, [loggedInUser]);

  return { user };
}
