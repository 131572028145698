import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { checkUserExistence, checkCitizenExistence } from '@app/api/auth.api';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { getAllParameters } from '@app/api/parameter.api';
import logo from 'assets/logo.png';

interface LoginFormData {
  username: string;
  password: string;
}

export const initValues: LoginFormData = {
  username: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const handleSubmit = async (values: LoginFormData) => {
    const { username, password } = values;

    try {
      const loginPayload = { username, password };
      setLoading(true);

      if (showPassword) {
        await dispatch(doLogin(loginPayload)).unwrap();
        const res = await getAllParameters();
        localStorage.setItem('parameters', JSON.stringify(res.data));
        navigate('/');
      } else {
        const userExists = await checkUserExistence(username);
        setUserExists(userExists);

        if (userExists) {
          setShowPassword(true);
        } else {
          const citizenExists = await checkCitizenExistence(username);

          if (citizenExists) {
            navigate('/auth/new-user', { state: { identityNumber: username } });
          } else {
            // User not found, handle error
            notificationController.error({ message: 'User not found.' });
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>
          {t('common.login')}
          <img src={logo} style={{ float: 'right', width: 110 }} />
        </Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        <Auth.FormItem
          name="username"
          label={t('common.email')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.email')} disabled={userExists} />
        </Auth.FormItem>
        {showPassword && (
          <Auth.FormItem
            label={t('common.password')}
            name="password"
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInputPassword placeholder={t('common.password')} />
          </Auth.FormItem>
        )}
        {showPassword && (
          <Auth.ActionsWrapper>
            <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
              <Auth.FormCheckbox>
                <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
              </Auth.FormCheckbox>
            </BaseForm.Item>
            <Link to="/auth/forgot-password">
              <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
            </Link>
          </Auth.ActionsWrapper>
        )}
        {showPassword && (
          <BaseForm.Item noStyle>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.login')}
            </Auth.SubmitButton>
          </BaseForm.Item>
        )}
        {!showPassword && (
          <BaseForm.Item noStyle>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              İlerle
            </Auth.SubmitButton>
          </BaseForm.Item>
        )}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
