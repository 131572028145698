import React, { useMemo } from 'react';
import { ActiveTournamentModel, ActiveTournamentWithRefereeAssignmentInfoModel } from '@app/domain/TournamentModel';
import { Text as H5 } from '@app/components/common/typography/H5/H5.styles';
import logo from 'assets/logo.png';
import { Card } from '@app/components/common/Card/Card';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import useParameters from '@app/hooks/useParameters';
import { Descriptions } from 'antd';
import { Status } from '@app/components/parameter/Status';

const TournamentWithAssignmentInfoCard = ({
  tournament,
  onAcceptAssignment,
  onRejectAssignment,
}: {
  tournament: ActiveTournamentWithRefereeAssignmentInfoModel;
  onAcceptAssignment?: (id: number) => void;
  onRejectAssignment?: (id: number) => void;
}) => {
  const { t, i18n } = useTranslation();

  const parameterCities = useParameters('ILLER');
  const parameterDistricts = useParameters('ILCELER');
  const parameterTournamentTypes = useParameters('TurnuvaTipi');
  const parameterRoundCountTypes = useParameters('TurnuvaTur');
  const parameterRefereeMissions = useParameters('TurnuvaHakemUnvanlari');

  const city = useMemo(() => {
    return parameterCities.parameterGroup.find((city) => city.id == tournament.cityPId)?.name;
  }, [parameterCities, tournament.cityPId]);

  const district = useMemo(() => {
    return parameterDistricts.parameterGroup.find((district) => district.id == tournament.countyPId)?.name;
  }, [parameterCities, tournament.countyPId]);

  const tournamentType = useMemo(() => {
    return parameterTournamentTypes.parameterGroup.find((type) => type.id == tournament.typePId)?.name;
  }, [parameterCities, tournament.typePId]);

  const tournamentRoundCount = useMemo(() => {
    return parameterRoundCountTypes.parameterGroup.find(
      (roundCountType) => roundCountType.id == tournament.numberOfRoundsPId,
    )?.name;
  }, [parameterRoundCountTypes, tournament.numberOfRoundsPId]);

  const assignedMission = useMemo(() => {
    return parameterRefereeMissions.parameterGroup.find((mission) => mission.id == tournament.missionPId)?.name;
  }, [parameterCities, tournament.typePId]);

  const startDate = new Date(tournament.startDate);
  const endDate = new Date(tournament.endDate);

  return (
    <Card padding={20}>
      <div style={{ display: 'flex', gap: '0.5rem' }}>
        <img
          src={
            // TODO Api tournament image ?
            logo
          }
          // style={{ }}
          width={50}
          height={50}
          alt="tournament image"
        />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', alignItems: 'start' }}>
          <H5>{tournament.name}</H5>
          <Descriptions layout="horizontal" size="small" column={{ xs: 1, sm: 2 }}>
            <Descriptions.Item
              label={`${t('translation:citizensTable.province')}/${t('translation:citizensTable.district')}`}
            >
              {city}/{district}
            </Descriptions.Item>
            <Descriptions.Item label={t('translation:tournament.tournamentType')}>{tournamentType}</Descriptions.Item>
            <Descriptions.Item label={t('translation:date')}>
              {startDate.toLocaleDateString(i18n.language)} - {endDate.toLocaleDateString(i18n.language)}
            </Descriptions.Item>
            <Descriptions.Item label={t('translation:round-count')}>{tournamentRoundCount}</Descriptions.Item>
            <Descriptions.Item label={t('translation:quota')}>
              {tournament.totalRefereeAssigneCount + ' / ' + tournament.registeredRefereeCount}
            </Descriptions.Item>
          </Descriptions>
          {tournament.tournamentRefereeId && tournament.status && tournament.status >= 2 && (
            <>
              <Descriptions layout="horizontal" size="small">
                <Descriptions.Item label={t('translation:assigned-mission')}>{assignedMission}</Descriptions.Item>
              </Descriptions>
              <div style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap' }}>
                {tournament.status == 2 ? (
                  <>
                    <Button
                      type="ghost"
                      size="small"
                      onClick={() => onRejectAssignment?.(tournament.tournamentRefereeId!)}
                    >
                      {t('translation:alerts.decline')}
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => onAcceptAssignment?.(tournament.tournamentRefereeId!)}
                    >
                      {t('translation:alerts.accept')}
                    </Button>
                  </>
                ) : tournament.status == 3 ? (
                  <Status color="blue" text={t('translation:accepted')} />
                ) : tournament.status == 4 ? (
                  <Status color="red" text={t('translation:declined')} />
                ) : tournament.status == 5 ? (
                  <Status color="green" text={t('translation:assigned')} />
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default TournamentWithAssignmentInfoCard;
